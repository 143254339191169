<template>
  <div class="hot-phone-card">
    <div class="title">
      {{ title }}
    </div>
    <div v-if="clockWork" class="clock-work">
      <clock class="clock-work__icon" />
      Работает в круглосуточном режиме
    </div>
    <div class="service-info">
      <div class="item">
        <div class="number-phone">
          {{ phone1.number_phone[0] }}
          <br />
          {{ phone1.number_phone[1] }}
        </div>
        <div class="name-service">
          {{ phone1.name_service }}
        </div>
      </div>
      <div v-if="phone2" class="item">
        <div class="number-phone">
          {{ phone2.number_phone[0] }}
          {{ phone2.number_phone[1] }}
        </div>
        <div class="name-service">
          {{ phone2.name_service }}
        </div>
      </div>
    </div>
    <div
      class="hot-phone-card__icon"
      :style="{'background-color': color}"
    >
      <img :src="require(`@/assets/img/guide/${icon}.svg`)">
    </div>
  </div>
</template>

<script>
import Clock from "@/components/Icons/guide/Clock";

export default {
  components: {
    Clock
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    clockWork: {
      type: Boolean,
      default: false
    },
    phone1: {
      type: Object,
      default: () => ({})
    },
    phone2: {
      type: Object,
      default: null
    },
    icon: {
      type: String,
      default: ""
    },
    color: {
      type: String,
      default: ""
    }
  }
}
</script>

<style lang="scss" scoped>
.hot-phone-card {
  position: relative;
  padding: 32px;
  border-radius: 24px;
  background-color: #3a3a3c;

  .title {
    width: 670px;
    margin-bottom: 32px;
    font-size: 28px;
    font-weight: normal;
    line-height: 34px;
  }

  .clock-work {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    font-size: 24px;
    font-weight: normal;
    line-height: 36px;
    opacity: 0.5;

    &__icon {
      margin-right: 11px;
    }
  }

  .service-info {
    display: flex;

    .item {
      flex: 0.8;
    }

    .number-phone {
      margin-bottom: 8px;
      font-size: 50px;
      line-height: 70px;
    }

    .name-service {
      font-size: 24px;
      font-weight: normal;
      line-height: 38px;
      opacity: 0.5;
    }
  }

  &__icon {
    position: absolute;
    top: -26px;
    right: 58px;
    padding: 26px;
    border-radius: 24px;
  }
}

</style>
