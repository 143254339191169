<template>
  <div class="guide">
    <div class="alert-box">
      <img class="alert-box__photo" src="@/assets/img/banners/guide-banner-1.jpg">
      <div class="alert-box__text">
        <h1 class="text-title">
          Звони 112
        </h1>
        <h3 class="text-description">
          Единая диспетчерская служба вызова экстренных оперативных служб
        </h3>
        <div class="services">
          <div class="item">
            <div class="wrap">
              <police-cap class="item-icon" />
            </div>
            <p class="item-title">
              Полиция
            </p>
          </div>
          <div class="item">
            <div class="wrap">
              <police-badge class="item-icon" />
            </div>
            <p class="item-title">
              МЧС
            </p>
          </div>
          <div class="item">
            <div class="wrap">
              <tool class="item-icon" />
            </div>
            <p class="item-title">
              Аварийная служба газа
            </p>
          </div>
          <div class="item">
            <div class="wrap">
              <medication class="item-icon" />
            </div>
            <p class="item-title">
              Скорая <br /> помощь
            </p>
          </div>
          <div class="item">
            <div class="wrap">
              <fire class="item-icon" />
            </div>
            <p class="item-title">
              Пожарная <br /> служба
            </p>
          </div>
          <div class="item">
            <div class="wrap">
              <psyho-help class="item-icon" />
            </div>
            <p class="item-title">
              Психологическая помощь
            </p>
          </div>
        </div>
      </div>
      <h1 class="alert-box__hot-phone">
        Телефоны <br /> горячих линий
      </h1>
      <div class="hot-phone-cards">
        <hot-phone-card
          v-for="(item, index) in dataCards"
          :key="index"
          :title="item.title"
          :clock-work="item.clock_work"
          :phone1="item.phone_1"
          :phone2="item.phone_2"
          :color="item.color"
          :icon="item.icon"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PoliceCap from "@/components/Icons/guide/PoliceCap";
import PoliceBadge from "@/components/Icons/guide/PoliceBadge";
import Tool from "@/components/Icons/guide/Tool";
import Medication from "@/components/Icons/guide/Medication";
import Fire from "@/components/Icons/guide/Fire";
import PsyhoHelp from "@/components/Icons/guide/PsyhoHelp";
import HotPhoneCard from "@/components/Parts/HotPhoneCard";

export default {
  components: {
    PoliceCap,
    PoliceBadge,
    Tool,
    Medication,
    Fire,
    PsyhoHelp,
    HotPhoneCard
  },
  data() {
    return {
      dataCards: [
        {
          title: "Горячая линия «Стоп коррупция»",
          phone_1: {
            number_phone: ["8-8639 22-58-69"],
            name_service: ""
          },
          clock_work: false,
          icon: "Handcuff",
          color: "#585858"
        },
        {
          title: "Горячая линия по вопросам нарушений в сфере потребительского рынка",
          phone_1: {
            number_phone: ["8-8639 26–06–07"],
            name_service: ""
          },
          clock_work: false,
          icon: "Suitcase",
          color: "#0b83ff"
        },
        {
          title: "Горячая линия по вопросам ЖКХ",
          phone_1: {
            number_phone: ["8-8639 25-20-40"],
          },
          phone_2: {
            number_phone: ["8-863-285-52-96"],
            name_service: "для звонков с сотового телефона и иногородним"
          },
          clock_work: false,
          icon: "Home",
          color: "#f7802a"
        },
        {
          title: "Единый общероссийский телефон доверия для детей и их родителей",
          phone_1: {
            number_phone: ["8-800-2000-122"],
            name_service: ""
          },
          clock_work: false,
          icon: "Family",
          color: "#0b83ff"
        },
        {
          title: "Психологическая помощь несовершеннолетним специалистами МБУ ЦППМС \"Гармония\" г. Волгодонска",
          phone_1: {
            number_phone: ["8-8639 24-64-56"],
            name_service: ""
          },
          clock_work: false,
          icon: "Family",
          color: "#0b83ff"
        },
        {
          title: "Уполномоченный по правам ребёнка в Ростовской области",
          phone_1: {
            number_phone: ["8-863-28-00-603"],
            name_service: ""
          },
          clock_work: false,
          icon: "Family",
          color: "#0b83ff"
        },
        {
          title: "Горячая линия по вопросам нарушения трудового законодательства",
          phone_1: {
            number_phone: ["8-8639 26-20-90"],
            name_service: ""
          },
          clock_work: false,
          icon: "Suitcase",
          color: "#0b83ff"
        },

      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.guide {
  height: 1555px;
  padding-top: 32px;
  overflow-x: hidden;
  overflow-y: auto;

  .alert-box {
    position: relative;
    margin: 0 32px;

    &__photo {
      border-radius: 24px;
    }

    &__text {
      position: absolute;
      top: 0;
      padding: 64px 32px 32px 32px;

      .text-title {
        font-size: 70px;
        font-weight: bold;
        line-height: 44px;
      }

      .text-description {
        width: 720px;
        font-size: 35px;
        font-weight: normal;
        line-height: 45px;
        color: rgba(#fff, 0.7);
      }

      .services {
        display: flex;
        flex-wrap: wrap;
        padding-top: 32px;

        .item {
          display: flex;
          align-items: center;
          width: 315px;
          margin-top: 32px;

          .wrap {
            padding: 17px;
            margin-right: 20px;
            border-radius: 50%;
            background: #b42228;
          }

          &-title {
            font-size: 24px;
            line-height: 28px;
            color: #fff;
          }
        }
      }
    }

    &__hot-phone {
      margin-top: 64px;
      margin-bottom: 58px;
      font-size: 70px;
      font-weight: bold;
      line-height: 80px;
    }

    .hot-phone-cards {
      .hot-phone-card {
        margin: 52px 0;
      }
    }
  }
}
</style>
